import useTranslateFunction from "@hooks/useTranslateFunction"
import { useUserCountry } from "@hooks/useUserCountry"
import { KeyboardEvent } from "react"

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"
import "react-phone-number-input/style.css"

export function CustomPhoneInput({ value, onChange }: any) {
  const { t } = useTranslateFunction()
  const { country } = useUserCountry()

  return (
    <>
      <PhoneInput
        placeholder={t("customPhoneInput_Escribe tu número de WhatsApp")}
        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
          e.key === "Enter" && e.preventDefault()
        }}
        value={value}
        onChange={onChange}
        countryCallingCodeEditable={false}
        // @ts-ignore
        defaultCountry={country || "MX"}
        displayInitialValueAsLocalNumber
        withCountryCallingCode={false}
        error={
          value
            ? isPossiblePhoneNumber(value)
              ? undefined
              : "Invalid phone number"
            : "Phone number required"
        }
      />
      {value && !isPossiblePhoneNumber(value) && (
        <div className="mt-2 text-xs text-red-500">
          {t("customPhoneInput_Número inválido")}
        </div>
      )}
    </>
  )
}
