import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "ui_components/shadcn/ui/dialog"
import { Button } from "ui_components/shadcn/ui/button"
import { Label } from "ui_components/shadcn/ui/label"
import { CustomPhoneInput } from "./UI/CustomPhoneInput"
import { useEffect, useState } from "react"
import { E164Number, isPossiblePhoneNumber } from "libphonenumber-js"
import { useUserCountry } from "@hooks/useUserCountry"
import Axios from "axios"
import useTranslateFunction from "@hooks/useTranslateFunction"
import { useUser } from "@hooks/useUser"
import { toast } from "react-toastify"
import { mixpanel } from "lib/mixpanel"

function isLocalStorageAvailable() {
  try {
    const testKey = "isLocalStorageAvailable"
    window.localStorage.setItem(testKey, "testValue")
    window.localStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

const POPUP_DISMISS_KEY = "sendWhatsAppExamplePopupDismissed"
const HIDE_POPUP_PERIOD = 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
const IS_LOCAL_STORAGE_AVAILABLE = isLocalStorageAvailable()

export function GetPhoneNumberPopUp() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [inMemoryDismissTime, setInMemoryDismissTime] = useState<string | null>(
    null
  )
  const [phoneNumberForTest, setPhoneNumberForTest] = useState<
    E164Number | undefined
  >()
  const { country } = useUserCountry()
  const { t, lang } = useTranslateFunction()
  const { user } = useUser()

  async function sendTestTextMessage() {
    if (
      phoneNumberForTest == null ||
      !isPossiblePhoneNumber(phoneNumberForTest as string)
    ) {
      return alert(
        t("sendMeReminderExample_El número de teléfono no es válido")
      )
    }

    try {
      await Axios.post("/api/whatsapp/sendMessage", {
        phoneNumber: phoneNumberForTest.replace("+", ""),
        providerNameForHeading: t(
          "sendMeReminderExample_Recordatorio de cita con [tu nombre o marca]"
        ),
        providerNameAndDetailsForBody: t(
          "sendMeReminderExample_Tienes una cita con [tu nombre o marca].\n\nPuedes personalizar los mensajes como desees."
        ),
        isFromLandingPage: true,
        locale: lang,
      })
      handleDismiss()
      toast.success(t("sendMeReminderExample_¡Listo! Revisa tu WhatsApp"))
      mixpanel.track("Generate lead", {
        source: "popup",
      })
    } catch (error) {
      alert(
        t(
          "sendMeReminderExample_Hubo un error enviando el mensaje al número que indicaste"
        )
      )
    }
  }

  function openDialog() {
    if (isDialogOpen) return

    if (user) return // Don't show the popup if the user is logged in

    let dismissedTime = null

    if (IS_LOCAL_STORAGE_AVAILABLE) {
      dismissedTime = window.localStorage.getItem(POPUP_DISMISS_KEY)
    } else {
      dismissedTime = inMemoryDismissTime
    }

    if (dismissedTime) {
      const now = new Date().getTime()
      const timeElapsed = now - parseInt(dismissedTime, 10)

      // @ts-ignore
      if (timeElapsed < HIDE_POPUP_PERIOD) {
        return // Don't show the popup
      }
    }

    mixpanel.track("Lead Gen Popup Opened")
    setIsDialogOpen(true)
  }

  const handleDismiss = () => {
    const currentTime = new Date().getTime().toString()

    if (IS_LOCAL_STORAGE_AVAILABLE) {
      window.localStorage.setItem(POPUP_DISMISS_KEY, currentTime)
    } else {
      setInMemoryDismissTime(currentTime)
    }

    setIsDialogOpen(false)
  }

  // Handle exit intent
  useEffect(() => {
    const handleExitIntent = (event) => {
      if (event.clientY < 0) {
        openDialog()
      }
    }

    document.addEventListener("mouseout", handleExitIntent)

    return () => {
      document.removeEventListener("mouseout", handleExitIntent)
    }
  }, [openDialog])

  // Handle scroll percentage
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      const docHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      const scrollPercent = (scrollTop / docHeight) * 100

      if (scrollPercent > 60) {
        openDialog()
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [openDialog])

  // Handle time delay
  useEffect(() => {
    const timer = setTimeout(() => {
      openDialog()
    }, 30000) // Set delay time in milliseconds (e.g., 30000 for 30 seconds)

    return () => clearTimeout(timer)
  }, [openDialog])

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleDismiss}>
      <DialogContent className="pt-14 sm:max-w-[425px]">
        <div>
          <video
            src="/pulsing-letter.mp4"
            autoPlay
            loop
            muted
            playsInline
            className=" mx-auto h-20"
          ></video>
        </div>
        <DialogHeader>
          <DialogTitle>¿Quieres recibir un ejemplo en tu WhatsApp?</DialogTitle>
          <DialogDescription>
            Te enviaremos un mensaje de ejemplo. Jamás te enviaremos spam.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="">
            <Label htmlFor="phone" className="text-right">
              ¿Cuál es tu WhatsApp?
            </Label>
            <CustomPhoneInput
              value={phoneNumberForTest}
              onChange={(value) => {
                setPhoneNumberForTest(value == undefined ? undefined : value)
              }}
              defaultCountry={country}
            ></CustomPhoneInput>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={sendTestTextMessage}>Enviar</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
