import { PlusIcon } from "@heroicons/react/24/outline"
import useTranslateFunction from "@hooks/useTranslateFunction"
import React from "react"
import { useState } from "react"
import Image from "next/image"

export const testimonials = [
  {
    name: "Mariana",
    title: {
      es: "Esteticista",
      en: "Esthetician",
      pt: "Esteticista",
    },
    text: {
      es: "La verdad es que Confirmafy es excelente. Llevo mi agenda donde quiero y les llegan perfecto los mensajes de confirmación a las pacientes, sin falta.",
      en: "The truth is that Confirmafy is excellent. I take my schedule wherever I want and the confirmation messages reach the patients perfectly, without fail.",
      pt: "A verdade é que o Confirmafy é excelente. Levo minha agenda para onde quiser e as mensagens de confirmação chegam perfeitamente às pacientes, sem falhas.",
    },
    stars: 5,
    image: "/testimonials/mariana-cachutt.jpeg",
    date: "Abr 14, 2023",
  },
  {
    name: "Constanza",
    title: {
      es: "Clínica Dental",
      en: "Dental Clinic",
      pt: "Clínica Dentária",
    },
    text: {
      es: "La verdad que excelente servicio. Los pacientes recuerdan su turno y a mi me ha ayudado mucho a organizar la agenda de la clínica.",
      en: "The truth is that it's an excellent service. The patients remember their appointments, and it has helped me a lot to organize the clinic's schedule.",
      pt: "A verdade é que é um excelente serviço. Os pacientes lembram de suas consultas, e me ajudou muito a organizar a agenda da clínica.",
    },
    stars: 5,
    image: "/testimonials/constanza.png",
    date: "Abr 08, 2023",
  },
  {
    name: "Ribera del Cardenal",
    title: {
      es: "Real Estate Developer",
      en: "Real Estate Developer",
      pt: "Desenvolvedor Imobiliário",
    },
    text: {
      es: "Confirmafy ha sido muy eficiente ya que por este medio es más fácil mantener una actualización con respecto a las citas agendadas para actuar de forma inmediata.",
      en: "Confirmafy has been very efficient because, through this means, it is easier to maintain an update regarding the scheduled appointments to act immediately.",
      pt: "Confirmafy tem sido muito eficiente, pois por este meio é mais fácil manter uma atualização em relação às consultas agendadas para agir imediatamente.",
    },
    stars: 5,
    image: "/testimonials/ribera-del-cardenal.png",
    date: "Abr 07, 2023",
  },
  {
    name: "Odontología FL",
    title: {
      es: "Clíncia dental",
      en: "Dental Clinic",
      pt: "Clínica Dentária",
    },
    text: {
      es: "Me resultó súper práctico por el momento y siempre me funcionó bien. Tengo menos inasistencias y los pacientes dan menos vueltas, o confirman o cancelan. En cambio, cuando uno les escribe personalmente empiezan a querer cambiar el turno y buscar otras opciones de horarios!",
      en: "It has been super practical for me so far and has always worked well. I have fewer no-shows, and the patients are more decisive; they either confirm or cancel. On the other hand, when you write to them personally, they start wanting to change their appointment and look for other time options!",
      pt: "Foi super prático para mim até agora e sempre funcionou bem. Tenho menos faltas e os pacientes são mais decididos; eles confirmam ou cancelam. Por outro lado, quando você escreve para eles pessoalmente, eles começam a querer mudar o horário e procurar outras opções de horário!",
    },
    stars: 5,
    image: "/testimonials/florencia-lobo.jpeg",
    date: "Mar 20, 2023",
  },
  {
    name: "Sonomedic",
    title: {
      es: "Ultrasonido Médico",
      en: "Ultrasound Medical",
      pt: "Ultrassom Médico",
    },
    text: {
      es: "Confirmafy me ha ayudado mucho porque automatiza los recordatorios a mis pacientes. Me ahorra muchísimo tiempo y a los pacientes les gusta este sistema. Es sencillo y super efectivo.",
      en: "Confirmafy has helped me a lot because it automates reminders to my patients. It saves me a lot of time, and the patients like this system. It's simple and super effective.",
      pt: "Confirmafy me ajudou muito porque automatiza lembretes para meus pacientes. Economiza muito tempo para mim e os pacientes gostam desse sistema. É simples e super eficaz.",
    },
    stars: 5,
    image: "/testimonials/sonomedic.jpg",
    date: "Feb 28, 2023",
  },
  {
    name: "Sofiface",
    title: {
      es: "Microblading & Nanoblading Artists",
      en: "Microblading & Nanoblading Artists",
      pt: "Artistas de Microblading e Nanoblading",
    },
    text: {
      es: "Nuestros clientes puedes venir opcionalmente a realizarse servicios de mantenimiento anual si lo desean, pero no solían recordarlo. Al usar el servicio de recordatorios automáticos de Confirmafy, hemos visto un aumento significativo de clientes mensuales por mantenimiento anual, lo que se ha traducido en un incremento del facturado general de la empresa. Es un sistema simple, intuitivo y bien integrado con Google calendar entre otros, es justo lo que necesitábamos.",
      en: "Our clients can optionally come for annual maintenance services if they wish, but they didn't usually remember it. By using Confirmafy's automatic reminder service, we have seen a significant increase in monthly customers for annual maintenance, which has resulted in an increase in the company's overall billing. It's a simple, intuitive system, and well integrated with Google Calendar, among others; it's just what we needed.",
      pt: "Nossos clientes podem vir opcionalmente para serviços de manutenção anual se desejarem, mas geralmente não se lembravam. Ao usar o serviço de lembretes automáticos do Confirmafy, vimos um aumento significativo de clientes mensais para manutenção anual, o que resultou em um aumento na faturação geral da empresa. É um sistema simples, intuitivo e bem integrado com o Google Calendar, entre outros; é exatamente o que precisávamos.",
    },
    stars: 5,
    image: "/testimonials/sofiface.jpg",
    date: "Feb 27, 2023",
  },
  {
    name: "My Dentist",
    title: {
      es: "Clínica odontológica",
      en: "Dental Clinic",
      pt: "Clínica Dentária",
    },
    text: {
      es: "Confirmafy es una excelente aplicación que nos ahorra mucho tiempo. Súper fácil de usar, me encanta que sea compatible con Google Calendar.  Ha disminuido el porcentaje de inasistencias y cuando nuestros pacientes cancelan, fácilmente podemos dar seguimiento para reagendar de nuevo. La atención que brindan para aclaraciones y asesoramiento es excelente.",
      en: "Confirmafy is an excellent application that saves us a lot of time. Super easy to use, I love that it's compatible with Google Calendar. It has reduced the percentage of no-shows, and when our patients cancel, we can easily follow up to reschedule. The attention they provide for clarifications and advice is excellent.",
      pt: "Confirmafy é um excelente aplicativo que nos economiza muito tempo. Super fácil de usar, adoro que seja compatível com o Google Calendar. Reduziu o percentual de faltas, e quando nossos pacientes cancelam, podemos facilmente fazer o acompanhamento para remarcar. O atendimento que eles fornecem para esclarecimentos e conselhos é excelente.",
    },
    stars: 5,
    image: "/testimonials/my-dentist.jpg",
    date: "Feb 27, 2023",
  },
  {
    name: "Mónica Ortiz",
    title: {
      es: "Psicoterapeuta y sexóloga",
      en: "Psychotherapist and sexologist",
      pt: "Psicoterapeuta e sexóloga",
    },
    text: {
      es: "Confirmafy ha mejorado mi calidad en el trabajo, es un medio fácil y eficaz de recordar y confirmar las citas con mis pacientes. Una muy buena aportación.",
      en: "Confirmafy has improved my quality of work; it's an easy and effective way to remind and confirm appointments with my patients. A very good contribution.",
      pt: "Confirmafy melhorou minha qualidade de trabalho; é uma maneira fácil e eficaz de lembrar e confirmar consultas com meus pacientes. Uma contribuição muito boa.",
    },
    stars: 5,
    image: "/testimonials/monica-ortiz.jpg",
    date: "Feb 25, 2023",
  },
  {
    name: "Julieta",
    title: {
      es: "Clínica Medical Care",
      en: "Medical Care Clinic",
      pt: "Clínica Medical Care",
    },
    text: {
      es: "Confirmafy nos ayudó a eficientar el tiempo que pasábamos confirmando pacientes 1 por 1. Es muy fácil de utilizar y los reportes nos ayudan a darle seguimiento a los pacientes que nos cancelan. El porcentaje de pérdida de seguimiento disminuyó considerablemente. Recomendamos el excelente servicio y atención ⭐",
      en: "Confirmafy helped us to make the time we spent confirming patients 1 by 1 more efficient. It is very easy to use, and the reports help us to follow up on the patients who cancel on us. The percentage of loss of follow-up has decreased considerably. We recommend the excellent service and attention ⭐",
      pt: "Confirmafy nos ajudou a tornar mais eficiente o tempo que passávamos confirmando pacientes 1 por 1. É muito fácil de usar, e os relatórios nos ajudam a acompanhar os pacientes que cancelam conosco. O percentual de perda de acompanhamento diminuiu consideravelmente. Recomendamos o excelente serviço e atenção ⭐",
    },
    stars: 5,
    image: "/testimonials/clinica-medical-care.jpg",
    date: "Feb 23, 2023",
  },
  {
    name: "Vito Caló",
    title: {
      es: "Fisioterapeuta creador de FisioTraining Center",
      en: "Physiotherapist creator of FisioTraining Center",
      pt: "Fisioterapeuta criador do FisioTraining Center",
    },
    text: {
      es: "Con Confirmafy el porcentaje de inasistencias de los pacientes agendados paso de un 40% a solo 5%. Gracias a Confirmafy por aliviar mi trabajo a la hora de agendar a los pacientes. Lo recomiendo y le doy 5 estrellas 🌟",
      en: "With Confirmafy, the percentage of no-shows of scheduled patients went from 40% to just 5%. Thanks to Confirmafy for relieving my work when scheduling patients. I recommend it and give it 5 stars 🌟",
      pt: "Com Confirmafy, o percentual de faltas de pacientes agendados passou de 40% para apenas 5%. Obrigado ao Confirmafy por aliviar meu trabalho ao agendar pacientes. Eu recomendo e dou 5 estrelas 🌟",
    },
    stars: 5,
    image: "/testimonials/vito-calo.jpeg",
    date: "Nov 29, 2022",
  },
  {
    name: "María de ComiendoConMaria.com",
    title: {
      es: "Nutricionista",
      en: "Nutritionist",
      pt: "Nutricionista",
    },
    text: {
      es: "Confirmafy me ahorra mucho tiempo. Es cómodo, sencillo, práctico y muy útil. En mi caso, al tener tantos pacientes me resulta un poco caro pero sin duda con el tiempo que gano me compensa. Lo recomiendo 100% si quieres olvidarte de recordar las visitas.",
      en: "Confirmafy saves me a lot of time. It is comfortable, simple, practical, and very useful. In my case, having so many patients, it is a bit expensive, but without a doubt, with the time I save, it compensates me. I recommend it 100% if you want to forget about remembering the visits.",
      pt: "Confirmafy me economiza muito tempo. É confortável, simples, prático e muito útil. No meu caso, tendo tantos pacientes, é um pouco caro, mas sem dúvida, com o tempo que economizo, me compensa. Eu recomendo 100% se você quiser esquecer de lembrar as visitas.",
    },
    stars: 5,
    image: "/testimonials/comiendo-con-maria.jpeg",
    date: "Dec 20, 2022",
  },
  {
    name: "Dr. Norma B. Lopez",
    title: {
      es: "Psícologo",
      en: "Psychologist",
      pt: "Psicólogo",
    },
    text: {
      es: "Excelente servicio, desde el primer día las citas se envían a tiempo, es muy sencillo confirmar, me han simplificado el trabajo, y la atención de primera, les estoy muy agradecida.",
      en: "Excellent service, from the first day the appointments are sent on time, it is very easy to confirm, they have simplified my work, and the attention is first class, I am very grateful to them.",
      pt: "Excelente serviço, desde o primeiro dia as consultas são enviadas a tempo, é muito fácil confirmar, eles simplificaram meu trabalho, e a atenção é de primeira classe, sou muito grato a eles.",
    },
    stars: 5,
    image: "/testimonials/dr-norma-beatriz-lopez.jpeg",
    date: "Nov 22, 2022",
  },
  {
    name: "Martha de ParkeSalud",
    title: {
      es: "Centro de Salud y Bienestar",
      en: "Health and Wellness Center",
      pt: "Centro de Saúde e Bem-estar",
    },
    text: {
      es: "Este programa nos ha ayudado de manera eficiente a recordarle a los pacientes sus citas. Lo que nos permite incrementar nuestro trabajo. Y hacerlo de manera eficiente. Gracias.",
      en: "This program has efficiently helped us remind patients of their appointments. Allowing us to increase our work. And do it efficiently. Thank you.",
      pt: "Este programa nos ajudou de forma eficiente a lembrar os pacientes de suas consultas. O que nos permite aumentar nosso trabalho. E fazer isso de forma eficiente. Obrigado.",
    },
    stars: 5,
    image: "/testimonials/parke-salud.png",
    date: "Feb 28, 2023",
  },
  {
    name: "Mikel",
    title: {
      es: "Centro Capilar Gandia",
      en: "GANDIA Hair Center",
      pt: "Centro Capilar Gandia",
    },
    text: {
      es: "Confirmafy ha sido todo un hallazgo, el sistema manda un aviso por WhatsApp a todas las citas programadas, ahora no debemos de perder muchísimo tiempo en avisar manualmente a los clientes de sus citas en nuestro centro y podemos dedicar ese tiempo a atender a nuestros clientes. Totalmente recomendable.",
      en: "Confirmafy has been a great find, the system sends a notice via WhatsApp to all scheduled appointments, now we don't have to waste a lot of time manually notifying clients of their appointments at our center and we can dedicate that time to serving our clients. Totally recommendable.",
      pt: "Confirmafy foi uma grande descoberta, o sistema envia um aviso via WhatsApp para todos os compromissos agendados, agora não precisamos perder muito tempo notificando manualmente os clientes de seus compromissos em nosso centro e podemos dedicar esse tempo a atender nossos clientes. Totalmente recomendável.",
    },
    stars: 5,
    image: "/testimonials/cropped-centro-capilar-gandia-logo.png",
    date: "Oct 14, 2022",
  },
  {
    name: "Alejandro",
    title: {
      es: "Centro de Nutrición Aguere",
      en: "Aguere Nutrition Center",
      pt: "Centro de Nutrição Aguere",
    },
    text: {
      es: "Antes usabamos un servicio que confirmaba por SMS. Nos cambiamos a Confirmafy y estamos viendo que la tasa de confirmaciones ha aumentado en más de un 40%. A parte es perfecto que se integra con Google Calendar.",
      en: "Before we used a service that confirmed by SMS. We switched to Confirmafy and we are seeing that the confirmation rate has increased by more than 40%. It is also perfect that it integrates with Google Calendar.",
      pt: "Antes usávamos um serviço que confirmava por SMS. Mudamos para o Confirmafy e estamos vendo que a taxa de confirmação aumentou em mais de 40%. Também é perfeito que se integra com o Google Calendar.",
    },
    stars: 5,
    image: "/testimonials/centro-aguere.jpeg",
    date: "Oct 31, 2022",
  },
  {
    name: "Dr. Jorge Reque",
    title: {
      es: "Psiquiatra infantil",
      en: "Child Psychiatrist",
      pt: "Psiquiatra infantil",
    },
    text: {
      es: "Estuve buscando miles de opciones hasta que encontré Confirmafy. Quería una herramienta sencilla que pueda sincronizarse con mi calendario Google y WhatsApp; una búsqueda muy exquisita pero sin querer lo hallé. Ya son 2 meses que lo uso y quedo muy conforme con el servicio. Funciona y lo mejor es que me ayudó a organizarme con mis pacientes y ellos también pueden recordar con facilidad su cita.",
      en: "I was looking for thousands of options until I found Confirmafy. I wanted a simple tool that can sync with my Google calendar and WhatsApp; a very exquisite search but I found it without wanting to. It's been 2 months since I've been using it and I'm very satisfied with the service. It works, and the best thing is that it helped me organize myself with my patients, and they can also easily remember their appointment.",
      pt: "Estive procurando milhares de opções até encontrar o Confirmafy. Eu queria uma ferramenta simples que pudesse sincronizar com meu calendário do Google e WhatsApp; uma busca muito requintada, mas encontrei sem querer. Já faz 2 meses que estou usando e estou muito satisfeito com o serviço. Funciona, e o melhor é que me ajudou a me organizar com meus pacientes, e eles também podem lembrar facilmente de sua consulta.",
    },
    stars: 5,
    image: "/testimonials/dr-reque.jpeg",
    date: "Oct 5, 2022",
  },
  {
    name: "Carlos",
    title: {
      es: "Fundador de Megalimpio",
      en: "Founder of Megalimpio",
      pt: "Fundador da Megalimpio",
    },
    text: {
      es: "Paso a comentarte lo bien que me ha funcionado el sistema! Llevo varios meses usándolo y me ha facilitado mucho el trabajo a la hora de que mis clientes puedan confirmar nuestras visitas, sobre todo porque siempre era complicado confirmar a los clientes ya que no antes no tenía el tiempo y ahora se hace manera automática! Ha Sido buenísimo.",
      en: "I'm here to tell you how well the system has worked for me! I've been using it for several months, and it has made my work much easier when my clients can confirm our visits, especially because it was always complicated to confirm with the clients since I didn't have the time before, and now it is done automatically! It has been great.",
      pt: "Estou aqui para te dizer o quanto o sistema funcionou bem para mim! Estou usando há vários meses, e facilitou muito o meu trabalho quando meus clientes podem confirmar nossas visitas, especialmente porque sempre foi complicado confirmar com os clientes, já que não tinha tempo antes, e agora é feito automaticamente! Tem sido ótimo.",
    },
    stars: 5,
    image: "/testimonials/megalimpio.jpeg",
    date: "Septiembre 23, 2022",
  },
  {
    name: "Lizet",
    title: {
      es: "LA CROIX Nanopigmentación",
      en: "LA CROIX Nanopigmentation",
      pt: "LA CROIX Nanopigmentação",
    },
    text: {
      es: "Estoy muy conforme con Confirmafy. De verdad te despreocupas por estar enviando mensajes a los pacientes. Lo que más me resultó cómodo es que yo usaba google calendar y se sincroniza perfectamente. Probé el periodo de prueba y ahora ya tengo mi plan. Gracias Ricardo!",
      en: "I am very satisfied with Confirmafy. You really don't have to worry about sending messages to patients. What I found most comfortable is that I used google calendar and it syncs perfectly. I tried the trial period, and now I have my plan. Thank you Ricardo!",
      pt: "Estou muito satisfeita com o Confirmafy. Você realmente não precisa se preocupar em enviar mensagens para os pacientes. O que achei mais confortável é que eu usava o google calendar e ele sincroniza perfeitamente. Experimentei o período de teste e agora tenho meu plano. Obrigado Ricardo!",
    },
    stars: 5,
    image: "/testimonials/lizet.jpeg",
    date: "Oct 25, 2022",
  },
  {
    name: "Karla de Pilaterapia",
    title: {
      es: "Fisioterapeuta",
      en: "Physiotherapist",
      pt: "Fisioterapeuta",
    },
    text: {
      es: "Con Confirmafy he podido organizar hasta mi tiempo libre! Ahora yo tranquila de que mis pacientes están enterados de la hora y lugar de su cita. Me ahorra tiempo libre de pantallas para aprovecharlos en otra cosa. Se ha convertido en mi secretaria virtual. Un servicio súper útil.",
      en: "With Confirmafy, I have been able to organize even my free time! Now I am calm that my patients are aware of the time and place of their appointment. It saves me screen-free time to take advantage of it for something else. It has become my virtual secretary. A super useful service.",
      pt: "Com o Confirmafy, consegui organizar até meu tempo livre! Agora estou tranquila de que meus pacientes estão cientes do horário e local de sua consulta. Economiza tempo livre de tela para aproveitá-lo para outra coisa. Tornou-se minha secretária virtual. Um serviço super útil.",
    },
    stars: 5,
    image: "/testimonials/karla-pilaterapia.jpeg",
    date: "Agosto 15, 2022",
  },
]

export function Testimonials({
  forceOnePerRow = false,
}: {
  forceOnePerRow?: boolean
}) {
  const { t } = useTranslateFunction()
  const [isShowingAllTestimonials, setIsShowingAllTestimonials] =
    useState(false)

  const bigScreenClasses = forceOnePerRow ? "" : "md:grid-cols-2 lg:grid-cols-3"

  return (
    <div
      id="testimonials"
      className={`relative  ${
        !isShowingAllTestimonials && "max-h-130 overflow-hidden"
      }`}
      style={{
        backgroundImage:
          "linear-gradient(45deg, hsl(240deg 100% 20%) 0%, hsl(244deg 98% 22%) 9%, hsl(249deg 97% 24%) 18%, hsl(253deg 95% 25%) 27%, hsl(258deg 94% 27%) 36%, hsl(262deg 93% 29%) 45%, hsl(267deg 92% 31%) 55%, hsl(271deg 90% 32%) 64%, hsl(275deg 90% 34%) 73%, hsl(280deg 88% 36%) 82%, hsl(284deg 86% 38%) 91%, hsl(289deg 85% 39%) 100%)",
      }}
    >
      <h2 className="pt-16 text-center text-4xl font-extrabold text-white">
        {t("testimonials_La opinión de nuestros clientes")}
      </h2>
      <div
        className={`mx-auto grid max-w-7xl grid-cols-1 gap-4 px-4 py-10 ${bigScreenClasses}`}
      >
        {[1, 2, 3].map((i) => {
          if (i === 1) {
            return (
              <div className="md:hidden" key={i}>
                {testimonials.map((testimonial, index) => (
                  <TestimonialSquare
                    key={index}
                    testimonial={testimonial}
                  ></TestimonialSquare>
                ))}
              </div>
            )
          } else if (i === 2) {
            // slice array half point
            const half = Math.ceil(testimonials.length / 2)
            const firstHalf = testimonials.slice(0, half)
            const secondHalf = testimonials.slice(half, testimonials.length)

            return (
              <React.Fragment key="2-rows">
                <div className="hidden md:flex md:flex-col lg:hidden">
                  {firstHalf.map((testimonial, index) => (
                    <TestimonialSquare
                      key={index}
                      testimonial={testimonial}
                    ></TestimonialSquare>
                  ))}
                </div>
                <div className="hidden md:flex md:flex-col lg:hidden">
                  {secondHalf.map((testimonial, index) => (
                    <TestimonialSquare
                      key={index}
                      testimonial={testimonial}
                    ></TestimonialSquare>
                  ))}
                </div>
              </React.Fragment>
            )
          } else {
            // slice testimonials array three parts
            const third = Math.ceil(testimonials.length / 3)
            const firstThird = testimonials.slice(0, third)
            const secondThird = testimonials.slice(third, third * 2)
            const thirdThird = testimonials.slice(
              third * 2,
              testimonials.length
            )

            return (
              <React.Fragment key="3-rows">
                <div className="hidden items-start lg:flex lg:flex-col">
                  {firstThird.map((testimonial, index) => (
                    <TestimonialSquare
                      key={index}
                      testimonial={testimonial}
                    ></TestimonialSquare>
                  ))}
                </div>
                <div className="hidden items-start lg:flex lg:flex-col">
                  {secondThird.map((testimonial, index) => (
                    <TestimonialSquare
                      key={index}
                      testimonial={testimonial}
                    ></TestimonialSquare>
                  ))}
                </div>
                <div className="hidden items-start lg:flex lg:flex-col">
                  {thirdThird.map((testimonial, index) => (
                    <TestimonialSquare
                      key={index}
                      testimonial={testimonial}
                    ></TestimonialSquare>
                  ))}
                </div>
              </React.Fragment>
            )
          }
        })}
      </div>
      <div
        className={`absolute bottom-0 z-10 flex h-80 w-full items-end justify-center ${
          isShowingAllTestimonials && "hidden"
        }`}
        style={{
          backgroundImage:
            "linear-gradient(to top, rgb(244,244,244), rgba(245,245,245,0))",
        }}
      >
        <button
          type="button"
          className="mb-6 mr-2 flex items-center justify-center rounded-full bg-gray-800 px-8 py-4 font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
          onClick={() => setIsShowingAllTestimonials(true)}
        >
          <span className="mr-4 h-6 w-6">
            <PlusIcon></PlusIcon>
          </span>

          {t("testimonials_Ver más opiniones")}
        </button>
      </div>
    </div>
  )
}

function TestimonialSquare({ testimonial, index }: any) {
  const { t, lang } = useTranslateFunction()

  return (
    <div className="mt-4 rounded bg-white p-8" key={index}>
      <div className="mb-4 flex">
        <div className="h-12 w-12">
          <Image
            className="rounded-full border border-white shadow"
            src={testimonial.image}
            alt={testimonial.name}
            width={48}
            height={48}
          />
        </div>
        <div className="ml-2">
          <div className=" font-bold">{testimonial.name}</div>
          <div className=" text-sm">{testimonial.title[lang]}</div>
        </div>
      </div>
      <div className="mb-4 mt-4">
        <Star></Star>
        <Star></Star>
        <Star></Star>
        <Star></Star>
        <Star></Star>
      </div>
      <div>{testimonial.text[lang]}</div>
      {/* <div className="mt-4 text-sm text-gray-600 ">{testimonial.date}</div> */}
    </div>
  )
}

function Star() {
  return (
    <svg
      viewBox="0 0 51 48"
      className="widget-svg"
      style={{ width: "24px", height: "24px", display: "inline-block" }}
    >
      <path
        className="star"
        d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
        style={{ fill: "rgb(255, 182, 33)" }}
      ></path>
    </svg>
  )
}
