import useTranslateFunction from "@hooks/useTranslateFunction"
import { testimonials } from "./Home/Testimonials"
import Image from "next/image"

export default function InlineTestimonials() {
  const { t } = useTranslateFunction()

  return (
    <div>
      <div className="inline-flex items-center divide-x divide-gray-300">
        <div className="flex flex-shrink-0 pr-5">
          <svg
            className="h-5 w-5 text-yellow-400"
            x-description="Heroicon name: mini/star"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className="h-5 w-5 text-yellow-400"
            x-description="Heroicon name: mini/star"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className="h-5 w-5 text-yellow-400"
            x-description="Heroicon name: mini/star"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className="h-5 w-5 text-yellow-400"
            x-description="Heroicon name: mini/star"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className="h-5 w-5 text-yellow-400"
            x-description="Heroicon name: mini/star"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="min-w-0 flex-1 py-1 pl-5 text-sm text-gray-500 sm:py-3">
          <div className="relative flex overflow-hidden text-left sm:overflow-visible">
            {testimonials.slice(0, 10).map((testimonial, i) => {
              return (
                <Image
                  key={i}
                  className="-mr-5 inline-block rounded-full border-2 border-white bg-gray-100 shadow sm:-mr-4"
                  src={testimonial.image}
                  alt={testimonial.name}
                  width={48}
                  height={48}
                />
              )
            })}
            <div className="absolute right-0 z-10 h-full w-8 bg-gradient-to-r from-gray-100/[0.06] to-gray-100 sm:hidden"></div>
          </div>
          <div className="mt-2">
            <span className="font-medium text-indigo-600">
              <a href="/#testimonials">
                {t("inlineTestimonials_Miles de usuarios aman Confirmafy")}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
