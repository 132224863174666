import { CheckIcon } from "@heroicons/react/24/outline"
import Axios from "axios"
import { useUserCountry } from "hooks"
import { CountryCode, E164Number } from "libphonenumber-js"
import { useState } from "react"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { mixpanel } from "lib/mixpanel"
import useTranslateFunction from "@hooks/useTranslateFunction"

export function SendMeReminderExample({
  isForBlogPost,
  hideTitle = false,
  justRemind = false,
}: {
  isForBlogPost?: boolean
  hideTitle?: boolean
  justRemind?: boolean
}) {
  const [phoneNumberForTest, setPhoneNumberForTest] = useState<
    E164Number | undefined
  >()
  const [messageSent, setMessageSent] = useState(false)
  const { country } = useUserCountry()
  const { t, lang } = useTranslateFunction()

  async function sendTestTextMessage(e: React.FormEvent) {
    e.preventDefault()

    if (
      phoneNumberForTest == null ||
      !isPossiblePhoneNumber(phoneNumberForTest as string)
    ) {
      return alert(
        t("sendMeReminderExample_El número de teléfono no es válido")
      )
    }

    try {
      await Axios.post("/api/whatsapp/sendMessage", {
        phoneNumber: phoneNumberForTest.replace("+", ""),
        providerNameForHeading: t(
          "sendMeReminderExample_Recordatorio de cita con [tu nombre o marca]"
        ),
        providerNameAndDetailsForBody: t(
          "sendMeReminderExample_Tienes una cita con [tu nombre o marca].\n\nPuedes personalizar los mensajes como desees."
        ),
        isFromLandingPage: true,
        justRemind,
        locale: lang,
      })
      setMessageSent(true)
      mixpanel.track("Generate lead", {
        source: "inline",
      })
    } catch (error) {
      alert(
        t(
          "sendMeReminderExample_Hubo un error enviando el mensaje al número que indicaste"
        )
      )
    }
  }

  if (isForBlogPost) {
    return (
      <div>
        {!hideTitle && (
          <h2 className="mb-2 text-center text-sm font-bold">
            {t(
              "sendMeReminderExample_¿Quieres recibir un ejemplo en tu WhatsApp?"
            )}
          </h2>
        )}

        <div>
          {!messageSent ? (
            <form className="flex" onSubmit={sendTestTextMessage}>
              <div className="self-center pl-2">
                <CustomPhoneInput
                  value={phoneNumberForTest}
                  onChange={(value) => {
                    setPhoneNumberForTest(
                      value == undefined ? undefined : value
                    )
                  }}
                  defaultCountry={country}
                ></CustomPhoneInput>
              </div>
              <button
                type="submit"
                className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              >
                {t("sendMeReminderExample_Enviar")}
              </button>
            </form>
          ) : (
            <div className="flex items-center justify-center text-center text-sm">
              <CheckIcon
                className="mr-2 inline-block h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              {t("sendMeReminderExample_¡Mensaje enviado! Revisa tu WhatsApp.")}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="relative my-10 lg:my-24">
      <div
        className="bg-warm-gray-50 absolute left-0 right-0 h-1/2"
        aria-hidden="true"
      />
      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="rounded-3xl bg-gradient-to-l from-sky-800 to-cyan-700 py-10 px-6 sm:py-16 sm:px-12 lg:flex lg:items-center lg:py-20 lg:px-20">
          <div className="lg:w-0 lg:flex-1">
            <h3 className="text-center text-3xl font-bold tracking-tight text-white">
              {t(
                "sendMeReminderExample_¿Quieres recibir un mensaje de ejemplo en tu WhatsApp?"
              )}
            </h3>
            {/* <p className="mt-4 max-w-3xl text-lg text-cyan-100">
              {t(
                "sendMeReminderExample_Deja tu número para enviarte un mensaje de confirmación de ejemplo."
              )}
            </p> */}
          </div>
          <div className="mt-12 flex justify-center  lg:mt-0 lg:ml-8 lg:flex-1">
            {!messageSent ? (
              <form className="sm:flex" onSubmit={sendTestTextMessage}>
                <div className="self-center rounded-md bg-white pl-2">
                  <CustomPhoneInput
                    value={phoneNumberForTest}
                    onChange={(value) => {
                      setPhoneNumberForTest(
                        value == undefined ? undefined : value
                      )
                    }}
                    defaultCountry={country}
                  ></CustomPhoneInput>
                </div>
                <button
                  type="submit"
                  className="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-green-400 px-5 py-3 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 focus:ring-offset-cyan-700 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                >
                  {t("sendMeReminderExample_Enviar")}
                </button>
              </form>
            ) : (
              <div className=" flex items-center justify-center text-center text-3xl text-white">
                <CheckIcon
                  className="mr-2 inline-block h-10 w-10 text-green-500"
                  aria-hidden="true"
                />
                {t(
                  "sendMeReminderExample_¡Mensaje enviado! Revisa tu WhatsApp."
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function CustomPhoneInput({
  value,
  onChange,
  defaultCountry = "VE",
}: {
  value: E164Number | undefined
  onChange: (value: string) => void
  defaultCountry: CountryCode | undefined
}) {
  return (
    <>
      <PhoneInput
        value={value}
        onChange={onChange}
        countryCallingCodeEditable={false}
        international
        defaultCountry={defaultCountry}
      />
    </>
  )
}
