/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline"
import useTranslateFunction from "@hooks/useTranslateFunction"
import Link from "next/link"

export function DesignedFor() {
  const { t } = useTranslateFunction()

  const features = [
    t("designedFor_list_Médicos, odontólogos, clínicas"),
    t("designedFor_list_Estéticas, peluquerías, barberías"),
    t("designedFor_list_Psicólogos, terapeutas, nutricionistas"),
    t("designedFor_list_Reuniones por Google Meet/Zoom"),
    t("designedFor_list_Servicio de autos, limpieza, reparaciones"),
    t("designedFor_list_Y más..."),
  ]

  return (
    <div className="bg-white" id="disenado-para">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8  lg:px-8">
        <div>
          <h3 className="text-base font-semibold uppercase tracking-wide text-indigo-600">
            {t("designedFor_¿Para quién es?")}
          </h3>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            {t("designedFor_Ideal para profesionales que dependen de citas")}
          </p>
          <p className="mt-4 text-lg text-gray-500">
            {t(
              "designedFor_Simplifica la gestión de citas y ahorra horas automatizando procesos por WhatsApp"
            )}
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="space-y-3 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-4 sm:gap-x-6 sm:gap-y-4 sm:space-y-0 lg:gap-x-4">
            {features.map((feature, index) => (
              <div key={index} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg font-medium leading-6 text-gray-900">
                    {feature}
                  </p>
                </dt>
                {/* <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd> */}
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="flex justify-center pb-10">
        <Link
          className="shadow-indigo-950/10 mx-auto inline-flex items-center justify-center  whitespace-nowrap rounded-lg bg-indigo-500 px-12 py-2.5 text-xl font-medium text-white shadow  transition-colors duration-150 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 lg:mx-0"
          href={`/signup`}
        >
          <div className="text-center">
            <div className="flex items-center">
              {t("signInButton_Crea tu cuenta gratis")}
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="ml-4 h-8 w-8"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </Link>
      </div>
    </div>
  )
}
