export function FormSection({
  title,
  description,
  children,
  hidden,
}: {
  title: string
  description: string | React.ReactNode
  children: React.ReactNode
  hidden?: boolean
}) {
  return (
    <div
      className={`bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 ${
        hidden && "hidden"
      }`}
    >
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
          <div className="mt-1 text-sm text-gray-500">{description}</div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">{children}</div>
      </div>
    </div>
  )
}
