import Link from "next/link"
import useTranslateFunction from "@hooks/useTranslateFunction"
import InlineTestimonials from "./InlineTestimonials"

export function SignInButton({ onClickViewDemo }: any) {
  const { t } = useTranslateFunction()

  return (
    <div className="max-w-md">
      <div className="">
        <div className="rounded-md">
          <Link
            className="shadow-indigo-950/10 mx-auto inline-flex items-center justify-center  whitespace-nowrap rounded-lg bg-indigo-500 px-12 py-2.5 text-xl font-medium text-white shadow  transition-colors duration-150 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 lg:mx-0"
            href={`/signup`}
          >
            <div className="text-center">
              <div className="flex items-center">
                {t("signInButton_Empieza gratis")}
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="ml-4 h-8 w-8"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
        {/* <div className="ml-4 rounded-md shadow">
          <button
            onClick={onClickViewDemo}
            className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg"
          >
            {t("signInButton_Ver demo")}
          </button>
        </div> */}
      </div>
      <ul className="mt-4 w-80 space-y-2 md:w-auto">
        <li className="flex items-center">
          <img
            className="mr-2 h-8 w-8"
            src="/app/google-calendar-icon.png"
            alt=""
          />
          {t("signInButton_Funciona con Google Calendar")}
        </li>
        {/* <li className="flex items-center">
          <Check></Check>
          {t("signInButton_7 días de prueba gratis. No hace falta tarjeta")}
        </li> */}
        {/* <li className="flex">
          <Check></Check>7 días de prueba gratis
        </li>
        <li className="flex">
          <Check></Check>No hace falta tarjeta de crédito
        </li>
        <li className="flex">
          <Check></Check>Cancela cuando quieras
        </li> */}
      </ul>
      <div className="mt-6">
        <InlineTestimonials></InlineTestimonials>
      </div>
    </div>
  )
}

function Check() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2 inline h-8 w-8 text-green-500"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  )
}
