import { Controller } from "react-hook-form"
import { CustomPhoneInput } from "./CustomPhoneInput"

export function PhoneNumberField({
  label,
  control,
  country,
  className = "",
}: {
  label: string
  control: any
  country: string
  className?: string
}) {
  return (
    <div className={`grid grid-cols-3 gap-6 ${className}`}>
      <div className="col-span-3 sm:col-span-2">
        <label
          htmlFor="company-website"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>

        <div className="mt-1 flex rounded-md ">
          <div className="w-full max-w-lg rounded-md">
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomPhoneInput
                  onChange={(value: any) => {
                    onChange(value == undefined ? null : value)
                  }}
                  value={value}
                  defaultCountry={country}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
