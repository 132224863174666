/* eslint-disable @calcom/eslint/deprecated-imports */
import dayjs from "dayjs"
// import dayjsBusinessTime from "dayjs-business-days2"
// import customParseFormat from "dayjs/plugin/customParseFormat"
// import isBetween from "dayjs/plugin/isBetween"
import isToday from "dayjs/plugin/isToday"
import timeZone from "dayjs/plugin/timezone"
// import localizedFormat from "dayjs/plugin/localizedFormat"
// import relativeTime from "dayjs/plugin/relativeTime"
// import timeZone from "dayjs/plugin/timezone"
// import toArray from "dayjs/plugin/toArray"
import utc from "dayjs/plugin/utc"

// dayjs.extend(customParseFormat)
// dayjs.extend(dayjsBusinessTime)
// dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(timeZone)
// dayjs.extend(localizedFormat)
// dayjs.extend(relativeTime)
// dayjs.extend(timeZone)
// dayjs.extend(toArray)
dayjs.extend(utc)

require("dayjs/locale/es")

export type Dayjs = dayjs.Dayjs

export type { ConfigType } from "dayjs"

export default dayjs
