import { Tooltip } from "@shopify/polaris"
import { useState } from "react"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export function Tabs({
  tabs,
  initialTab = 0,
}: {
  tabs: any
  initialTab?: number
}) {
  const [currentTab, setCurrentTab] = useState(initialTab)

  return (
    <div>
      <div className="">
        <nav
          className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => {
            let content = (
              <button
                key={tab.name}
                onClick={() => setCurrentTab(tabIdx)}
                className={classNames(
                  tabIdx === currentTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative w-full min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                disabled={tab.disabled}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tabIdx === currentTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </button>
            )

            if (tab.disabled) {
              return (
                <div className="group relative min-w-0 flex-1">
                  <Tooltip content={tab.disabledTooltip}>{content}</Tooltip>
                </div>
              )
            }

            return content
          })}
        </nav>
      </div>
      <div>{tabs[currentTab].component}</div>
    </div>
  )
}
