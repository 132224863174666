import {
  Cog8ToothIcon,
  LinkIcon,
  SquaresPlusIcon,
} from "@heroicons/react/20/solid"
import { BookingMain } from "./Bookings/BookingsPage/BookingsMain"
import { useState } from "react"

//
const features = [
  {
    name: "Link de Autoagenda:",
    description:
      "Crea un link para que tus clientes elijan su cita de manera independiente.",
    icon: LinkIcon,
  },
  {
    name: "A tu medida:",
    description:
      "Define las reglas de cuando estás disponible, los servicios que ofreces y el tiempo de cada servicio.",
    icon: Cog8ToothIcon,
  },
  {
    name: "Todo en una misma plataforma:",
    description: "Autoagenda viene incluido en todos los planes de Confirmafy.",
    icon: SquaresPlusIcon,
  },
]

export function AutoAgendaExample() {
  const [selectedEventTypeId, setSelectedEventTypeId] = useState<string | null>(
    null
  )

  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Autoagendamiento
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Deja que tus clientes elijan su cita con un simple link
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Crea y comparte tu link de autoagenda para que tus clientes
                escojan el día y hora de su cita.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div
            className="relative mt-6 rounded-xl sm:mt-0"
            style={{ background: "#192231" }}
          >
            <div className="pulsate absolute right-40 -top-12">
              <img
                alt="arrow"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTUiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1NSAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC4yIiBkPSJNMC4yNzY3NjYgMi42MjA3MkMyLjAxNzA0IDAuNjU4Mjg5IDQuMzEwMjggMC4yOTUwODYgNi40NDYzNSAwLjIwMTA3NkM5LjQxNTkgMC4xMDUzNTIgMTIuMzk5NCAwLjI5MTI2NyAxNS4yMjU4IDAuNzQ2MzY0QzI4LjE1MTYgMy4xNTIzNiAzNy42NjMzIDkuNjc1MTIgNDQuMDgzMiAxOS41NTg2QzQ1LjcwNDggMjIuMDc3NSA0Ni45MjM2IDI0Ljg3ODggNDguNDU0NCAyNy44NTg3QzUwLjkyNTcgMjUuMzU3MSA1MS40OTQ5IDIxLjkzNzUgNTMuODk5OSAxOS4yNDRDNTQuNzE0NyAyMC4zOTQ1IDU0LjI1NzIgMjEuNDgzNyA1My45MDQ1IDIyLjM5MzVDNTIuNDQxMyAyNi4xMjIxIDUwLjkwMzYgMjkuODc2NiA0OS4yNjkzIDMzLjU5MjhDNDguNDY3MyAzNS4zNzQgNDcuNTQzIDM1LjgzNjggNDUuODAzOCAzNS4xNDkxQzQyLjE1NDUgMzMuNzYxIDM5LjQ0NTcgMzEuNDc0OSAzNy43NzQgMjguMzI4OEMzNy43Mjk4IDI4LjIwMDggMzcuODg2OSAyNy45MzE3IDM4LjA3NDMgMjcuNTA4OEM0MS41MjEyIDI3LjEwNDggNDEuOTkyNiAzMC44ODA3IDQ1LjE5MTUgMzEuMjA3QzQ0Ljg1NzQgMjYuMzgxIDQyLjUwMzYgMjIuNDY4MSAzOS43NDcxIDE4LjgzNzdDMzYuODQ5NyAxNS4wNDEyIDMzLjU3MTYgMTEuNTkxMiAyOS4zMzA5IDguOTc1MjhDMjUuMTg2OCA2LjM5NzYgMjAuNTQwOSA0Ljc4MTE3IDE1LjU4OSAzLjQ4NTQ1QzEwLjY4OTQgMi4xIDUuNjIyMTggMS45MTgzNyAwLjI3Njc2NiAyLjYyMDcyWiIgZmlsbD0iIzBEMTkyNyIvPgo8L3N2Zz4K"
                className="w-12 -scale-x-100 pt-1.5"
              ></img>
              <span className="absolute -top-1 left-14 w-48  text-gray-400">
                ¡Prueba autoagenda!
              </span>
            </div>
            <div className=" rounded-md bg-gray-800 p-2 sm:py-8 sm:px-10">
              <div className="rounded-md bg-white">
                <BookingMain
                  isHomePage={true}
                  selectedEventTypeId={selectedEventTypeId}
                  setSelectedEventTypeId={(id) => {
                    setSelectedEventTypeId(id)
                  }}
                  slug={"ejemplo"}
                ></BookingMain>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
