export function TextField({
  label,
  children,
}: {
  label: string
  children: any
}) {
  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-3 sm:col-span-2">
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>

        <div className="mt-1 flex rounded-md">{children}</div>
      </div>
    </div>
  )
}
