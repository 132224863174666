import useTranslateFunction from "@hooks/useTranslateFunction"
import Link from "next/link"
import { getWhatsAppUrl } from "ui_components/WhatsAppWidget"

export function Faq() {
  const { t, tWithComponents } = useTranslateFunction()

  return (
    <div id="faq" className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              {t("faq_Preguntas Frecuentes")}
            </h2>
            {/* <p className="mt-4 text-lg text-gray-500">
              ¿No encuentras la respuesta a tu pregunta? Escríbeme por WhatsApp
              presionando el ícono en la parte inferior derecha de tu pantalla.
            </p> */}
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-12">
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  {t("faq_¿Cómo funcionan los pagos?")}
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {t(
                    "faq_Confirmafy es una subscripción, puedes pagar mensual o anualmente. Nuestros planes se adaptan a la cantidad de citas que tienes al mes."
                  )}
                </dd>
              </div>
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  {t("faq_¿Qué pasa si no me gusta Confirmafy?")}
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {t(
                    "faq_Puedes solicitar un reembolso en los primeros 30 días de tu subscripción."
                  )}{" "}
                  {tWithComponents(
                    "faq_Aquí puedes leer nuestra <0>politica de reembolso</0>.",
                    [
                      <Link
                        href="/fulfilment-policy"
                        className=" text-blue-500"
                      ></Link>,
                    ]
                  )}
                </dd>
              </div>
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  {t("faq_¿Qué apps de calendarios soporta Confirmafy?")}
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {t(
                    "faq_Tienes dos opciones puedes utilizar el Calendario que viene dentro de Confirmafy, o puedes conectar tu cuenta de Google Calendar."
                  )}
                </dd>
              </div>
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  {t("faq_¿Tienes más preguntas?")}
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {tWithComponents(
                    "faq_Aquí estamos a tu orden. <0>Click aquí para escribirnos por WhatsApp</0>.",
                    [
                      <Link
                        href={getWhatsAppUrl(
                          t("faq_Hola, tengo una pregunta sobre Confirmafy")
                        )}
                        className=" text-blue-500"
                        target="_blank"
                      ></Link>,
                    ]
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
