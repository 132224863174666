import { default as ReactModal } from "react-modal"
import YouTube, { YouTubeProps } from "react-youtube"

export function YoutubeModal({
  isOpen,
  onClose,
  videoId,
}: {
  isOpen: boolean
  onClose: () => void
  videoId: string
}) {
  const opts: YouTubeProps["opts"] = {
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }

  return (
    <ReactModal
      className="relative mx-auto mt-20 max-w-3xl rounded border-none bg-white"
      isOpen={isOpen}
      contentLabel=""
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{
        content: {
          border: "none",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        },
      }}
    >
      <YouTube videoId={videoId} opts={opts} />

      <div
        className="absolute top-0 right-0 text-right"
        style={{ marginTop: "-50px" }}
      >
        <button
          className="mb-4 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </ReactModal>
  )
}
