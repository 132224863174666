import Head from "next/head"
import { withRouter } from "next/router"
import React from "react"

const locales = ["es", "en", "pt", "x-default"]

const Page = ({
  children,
  date,
  description,
  image = "/images/banner-open-graph.jpg",
  title = "Confirmafy",
  keywords,
  router,
  isBookingPage = false,
  permalinks,
}) => {
  const domain = "https://confirmafy.com"
  const url = router && router.asPath ? router.asPath : undefined
  const featuredImage = domain + image
  const locale = router.locale

  let canonical
  if (locale === "es") {
    canonical = domain + url
  } else {
    const finaUrl = permalinks ? permalinks[locale] : url
    canonical = `${domain}/${locale}${finaUrl}`
  }

  if (canonical.endsWith("/")) {
    canonical = canonical.slice(0, -1)
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        {description && <meta content={description} name="description" />}
        {keywords && <meta content={keywords} name="keywords" />}
        <meta content="follow, index" name="robots" />
        <meta content="#ffffff" name="theme-color" />
        <meta content="#ffffff" name="msapplication-TileColor" />
        <meta name="msapplication-TileColor" content="#da532c" />
        {url && <link href={canonical} rel="canonical" />}
        <meta content={locale} property="og:locale" />
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" />
        <meta content={canonical} property="og:url" />
        {isBookingPage == false &&
          locales.map((locale) => {
            let href
            if (locale === "x-default" || locale === "es") {
              href = domain + url
            } else {
              const finaUrl = permalinks ? permalinks[locale] : url
              href = `${domain}/${locale}${finaUrl}`
            }

            if (href.endsWith("/")) {
              href = href.slice(0, -1)
            }

            return (
              <link
                key={locale}
                hrefLang={locale}
                href={href}
                rel="alternate"
              />
            )
          })}

        {featuredImage && (
          <>
            <meta content={featuredImage} property="og:image" />
            <meta content={description} property="og:image:alt" />
          </>
        )}
        {date && (
          <>
            <meta content="article" property="og:type" />
            <meta
              content={new Date(date).toISOString()}
              property="article:published_time"
            />
          </>
        )}
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="@ricardomacario" name="twitter:site" />
        <meta content="@ricardomacario" name="twitter:creator" />
      </Head>
      <div>{children}</div>
    </>
  )
}

export default withRouter(Page)
