export function getTranslatedStringForLocale(
  translations: Record<string, string>,
  locale: string
) {
  if (!translations[locale]) {
    console.error(`No translation for locale ${locale}`)
    return translations["es"]
  }

  return translations[locale]
}

export function getProperLocaleCodeForWhatsAppTemplate(locale: string) {
  // Transforms user locale to the one that WhatsApp uses

  if (locale === "pt") {
    return "pt_BR"
  } else if (locale === "es") {
    return "es"
  } else if (locale === "en") {
    return "en"
  } else if (locale === "it") {
    return "it"
  } else if (locale === "fr") {
    return "fr"
  } else {
    throw new Error(`No WhatsApp template for locale ${locale}`)
  }
}
