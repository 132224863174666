import useTranslateFunction from "@hooks/useTranslateFunction"
import Link from "next/link"

export function HowDoesItWorkSection() {
  const { t } = useTranslateFunction()

  const communicationFeatures = [
    {
      id: 1,
      name: t("howDoesItWork_Mensaje de confirmación enviado"),
      description: t(
        "howDoesItWork_Hemos enviado el mensaje de confirmación a tu cliente y esperamos su respuesta."
      ),
      icon: "🟡",
    },
    {
      id: 2,
      name: t("howDoesItWork_Cita confirmada"),
      description: t("howDoesItWork_Tu cliente confirmó la cita."),
      icon: "🟢",
    },
    {
      id: 3,
      name: t("howDoesItWork_Cita cancelada"),
      description: t("howDoesItWork_Tu cliente canceló la cita."),
      icon: "🔴",
    },
  ]

  return (
    <div
      className="overflow-hidden bg-gray-50 py-16 lg:py-24"
      id="como-funciona"
    >
      <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            {t(
              "howDoesItWork_La forma más simple de agendar y confirmar citas con tus clientes"
            )}
          </h2>
          {/* <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
            {t("howDoesItWork_Utilizar Confirmafy es así de fácil:")}
          </p> */}
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {t(
                "howDoesItWork_Paso 1: Crea citas en nuestra App o en tu Google Calendar"
              )}
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              {t(
                "howDoesItWork_Te damos la opción de utilizar nuestro calendario o Google Calendar. Puedes crear citas desde Android, iOS o la web."
              )}
            </p>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <video
              className="relative mx-auto "
              autoPlay
              loop
              muted
              playsInline
              src="/create-event-video.mp4"
              alt=""
            />
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                {t(
                  "howDoesItWork_Paso 2: Confirmafy le escribe a tu cliente automáticamente por WhatsApp"
                )}
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                {t(
                  "howDoesItWork_24 horas antes de la cita, nuestro sistema le mandará un mensaje por WhatsApp a tu cliente para confirmar la cita."
                )}
              </p>
              <p className="mt-3 text-lg text-gray-500">
                {t(
                  "howDoesItWork_Automáticamente actualizamos tu calendario con el estado de la cita:"
                )}
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-indigo-100 text-xl text-white">
                        {/* <item.icon className="h-6 w-6" aria-hidden="true" /> */}
                        {item.icon}
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img
                className="relative mx-auto"
                src="/google-calendar-with-events.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="relative z-10 flex justify-center pt-14">
            <Link
              className="shadow-indigo-950/10 mx-auto inline-flex items-center justify-center  whitespace-nowrap rounded-lg bg-indigo-500 px-12 py-2.5 text-xl font-medium text-white shadow  transition-colors duration-150 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 lg:mx-0"
              href={`/signup`}
            >
              <div className="text-center">
                <div className="flex items-center">
                  {t("signInButton_Crea tu cuenta gratis")}
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="ml-4 h-8 w-8"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
