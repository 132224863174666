import { SpeakerWaveIcon } from "@heroicons/react/24/outline"
import Link from "next/link"
import DateDiff from "date-diff"
import { trpc } from "lib/trpc"
import type { Subscription, User } from "@prisma/client"
import { useUser } from "hooks"
import { useMobileAppHelper } from "lib/mobileApp"
import useTranslateFunction from "@hooks/useTranslateFunction"

export function SelectPlanBanner() {
  const { user, isLoading: isLoadingUser } = useUser()

  const maxNumberOfMessagesPerMonth =
    user?.subscription?.maxNumberOfMessagesPerMonth ?? 25
  const { data: reportData, isLoading: isLoadingCounts } =
    trpc.report.getReportDataForPeriod.useQuery(
      {
        period: {
          gte: user?.subscription?.current_period_start || new Date(),
          lte: user?.subscription?.current_period_end || new Date(),
        },
      },
      {
        enabled: !isLoadingUser,
      }
    )

  if (isLoadingUser || isLoadingCounts || reportData == null) return null

  if (
    user?.organizationId != null &&
    user?.organization?.ownerUserId !== user?.id
  ) {
    return null
  }

  if (user?.appMode === "events") {
    return null
  }

  if (user?.subscription?.status === "trialing") {
    return (
      <Banner
        subscription={user.subscription}
        maxNumberOfMessagesPerMonth={maxNumberOfMessagesPerMonth}
        totalMessagesSent={reportData.counts?.totalMessagesSent ?? 0}
      />
    )
  } else if (
    user?.subscription?.status === "past_due" ||
    user?.subscription?.status === "incomplete_expired" ||
    user?.subscription?.status === "incomplete"
  ) {
    return <PaymentFailed user={user} />
  }

  return null
}

function Banner({
  subscription,
  maxNumberOfMessagesPerMonth,
  totalMessagesSent,
}: {
  subscription: Subscription
  maxNumberOfMessagesPerMonth: number
  totalMessagesSent: number
}) {
  const { t } = useTranslateFunction()
  const { isMobileApp } = useMobileAppHelper()
  const diff = new DateDiff(
    new Date(subscription.current_period_end),
    new Date()
  )

  const numberOfMessagesLeft = maxNumberOfMessagesPerMonth - totalMessagesSent
  const numberOfDaysLeft = Math.floor(diff.days())

  let message
  let trialOver = false

  if (diff.days() > 0 && numberOfMessagesLeft > 0) {
    message = t(
      "banners_Te quedan {{numerOfDays}} días o {{numberOfMessages}} confirmaciones del periodo de prueba.",
      {
        numerOfDays: numberOfDaysLeft,
        numberOfMessages: numberOfMessagesLeft,
      }
    )

    if (!isMobileApp) {
      message += " " + t("banners_ ¿Quieres más? Escoge un plan 👉")
    }
  } else {
    trialOver = true
    message = t(
      "banners_Tu período de prueba se acabó. Tienes que escoger un plan que los mensajes se sigan enviando"
    )
  }

  return (
    <div className={`${trialOver ? " bg-red-600" : "bg-indigo-600"}`}>
      <div className="mx-auto px-3 py-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className=" flex flex-1 items-center">
            {trialOver ? (
              <span className="flex rounded-lg p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-8 w-8  text-yellow-300"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>
              </span>
            ) : (
              <span className="flex rounded-lg p-2">
                <SpeakerWaveIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
            )}

            <p className="ml-3 text-base font-medium text-white">
              <span className="inline">{message}</span>
            </p>
          </div>
          <div className="order-3  ml-2 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <Link legacyBehavior href="/app/subscribe">
              <a className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 font-medium text-indigo-600 shadow-sm hover:bg-indigo-50">
                {t("banners_Escoger plan")}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function PaymentFailed({
  user,
}: {
  user: User & {
    subscription: Subscription | null
  }
}) {
  const { t } = useTranslateFunction()

  return (
    <div className="bg-red-600">
      <div className="mx-auto px-3 py-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className=" flex flex-1 items-center">
            <span className="flex rounded-lg p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-8 w-8  text-yellow-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>
            </span>
            <p className="ml-3 text-base font-medium text-white">
              <span className="inline">
                {t(
                  "banners_El pago de tu subscripción falló. Los mensajes de confirmación no se están enviando."
                )}
              </span>
            </p>
          </div>
          <div className="order-3  ml-2 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href={user.subscription!.lastInvoiceThatFailedPaymentCheckoutURL!}
              target="_blank"
              className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 font-medium text-red-600 shadow-sm hover:bg-indigo-50"
            >
              {t("banners_Completar Pago")}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
