import { Disclosure } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { useUser } from "hooks"
import { useRouter } from "next/router"
import useTranslateFunction from "@hooks/useTranslateFunction"
import Link from "lib/I18N/Link"
import { LanguagePicker } from "./UI/LanguagePicker"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export function Nav({ sticky = false }) {
  const { t } = useTranslateFunction()
  const { user, isLoading } = useUser()

  // get path from router
  const { pathname: path, locale } = useRouter()

  let navigation = [
    { name: t("nav_funciones"), href: "/funciones", current: false },
    // {
    //   name: t("nav_agenda"),
    //   href: "/agenda-online-para-clientes",
    //   current: false,
    //   hide: locale !== "es",
    // },
    // {
    //   name: t("nav_autoagenda"),
    //   href: "/sistema-de-reservas",
    //   current: false,
    //   hide: locale !== "es",
    // },
    { name: t("nav_precio"), href: "/#precio", current: false },
    { name: t("nav_blog"), href: "/blog", current: false },
    // {
    //   name: t("nav_movil"),
    //   href: "/aplicacion-para-agendar-citas",
    //   current: false,
    //   hide: locale !== "es",
    // },
    // {
    //   name: t("nav_manual"),
    //   href: "/manual",
    //   current: false,
    //   hideOnMobileApp: true,
    //   useRegularLink: true,
    //   hide: locale !== "es",
    // },
  ].filter((item) => !item.hide)

  function isCurrent(href) {
    if (path === "/app" && href === "/app") {
      return href === path
    } else if (path.indexOf(href) !== -1 && href !== "/app") {
      return true
    }

    return false
  }

  return (
    <Disclosure
      as="nav"
      className={`${
        sticky && "sticky"
      } top-0 z-40 border-b border-gray-200 bg-white`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 lg:px-8">
            <div className="flex h-16 md:justify-between">
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="ml-2 flex md:ml-0">
                <Link legacyBehavior href="/">
                  <a className="flex flex-shrink-0 items-center">
                    <img
                      className={`w-6 sm:mr-2 sm:w-10 ${
                        !isLoading && user && "block"
                      }`}
                      src="/logo.png"
                      alt="Confirmafy logo"
                    />
                    <div className="text-md font-semibold text-gray-800 sm:text-2xl md:mr-2">
                      Confirmafy
                    </div>
                  </a>
                </Link>

                <div className="ml-2 hidden md:-my-px md:space-x-4 lg:flex">
                  {navigation.map((item) => {
                    if (item.useRegularLink) {
                      return (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            isCurrent(item.href)
                              ? "border-indigo-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                          )}
                          aria-current={
                            isCurrent(item.href) ? "page" : undefined
                          }
                        >
                          {item.name}
                        </a>
                      )
                    }

                    return (
                      <Link legacyBehavior key={item.name} href={item.href}>
                        <a
                          className={classNames(
                            isCurrent(item.href)
                              ? "border-indigo-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                          )}
                          aria-current={
                            isCurrent(item.href) ? "page" : undefined
                          }
                        >
                          {item.name}
                        </a>
                      </Link>
                    )
                  })}
                </div>
              </div>
              {(!user || isLoading) && (
                <div className="item ml-auto mr-2 flex md:mr-0">
                  <span className="mr-2 flex items-center xs:mr-4">
                    <LanguagePicker></LanguagePicker>
                  </span>

                  <Link legacyBehavior href="/login">
                    <a
                      className={
                        "mr-4  hidden items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 md:inline-flex"
                      }
                    >
                      Login
                    </a>
                  </Link>
                  <Link legacyBehavior href="/signup">
                    <a className="self-center rounded-md border border-transparent bg-indigo-600 px-1 py-2 text-xs font-bold text-white hover:bg-indigo-700  sm:mr-0 sm:px-4 sm:text-base">
                      {t("nav_Empieza gratis")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={3}
                        stroke="currentColor"
                        className="ml-1 hidden h-4 w-4 xs:inline-block"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </a>
                  </Link>
                </div>
              )}
              {user && !isLoading && (
                <div className="ml-auto mr-2 flex md:mr-0">
                  <span className="mr-2 flex items-center pt-1 xs:mr-4">
                    <LanguagePicker></LanguagePicker>
                  </span>
                  <Link legacyBehavior href="/app">
                    <a className="self-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-xs font-bold text-white hover:bg-indigo-700 sm:ml-4 sm:mr-0 sm:px-4 sm:text-base">
                      {t("nav_goToApp")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={3}
                        stroke="currentColor"
                        className="ml-1 inline-block h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {!user && (
                <Disclosure.Button
                  key="login"
                  as="a"
                  href="/login"
                  className={classNames(
                    "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                    "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                  )}
                >
                  Login
                </Disclosure.Button>
              )}
              {navigation.map((item) => {
                if (item.useRegularLink) {
                  return (
                    <a
                      href={item.href}
                      key={item.name}
                      className={classNames(
                        isCurrent(item.href)
                          ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                      )}
                    >
                      {item.name}
                    </a>
                  )
                }
                return (
                  <Link legacyBehavior href={item.href} key={item.name}>
                    <a
                      className={classNames(
                        isCurrent(item.href)
                          ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                      )}
                    >
                      {item.name}
                    </a>
                  </Link>
                )
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
