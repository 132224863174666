import React, { useState } from "react"
import { YoutubeModal, SignInButton } from "."
import useTranslateFunction from "@hooks/useTranslateFunction"
import Image from "next/image"

export function Hero() {
  const { t, lang } = useTranslateFunction()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className="bg-white">
        <div className="relative mx-auto grid w-full max-w-7xl grid-cols-1   pt-14 md:px-16 lg:grid-cols-2 lg:px-0">
          <div className="flex items-center pb-4 text-center lg:text-left">
            <div className="px-4 sm:px-8">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl">
                <span className="block xl:inline">
                  {t("hero_Agenda, Confirma y Recuerda Citas por WhatsApp")}
                </span>{" "}
              </h1>
              <p className="mx-auto mt-6 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                {t(
                  "hero_Confirma asistencia. Elimina las ausencias. Cargamos las citas de tu agenda y confirmamos a tus clientes. Todo automatizado, empieza en minutos."
                )}
              </p>

              <div className="mt-8 flex justify-center lg:justify-start">
                <SignInButton
                  onClickViewDemo={() => setIsModalOpen(true)}
                ></SignInButton>
              </div>
            </div>
          </div>
          <div className="">
            <div className="h-110">
              <div className="relative h-full w-full">
                <Image
                  className="h-full w-full object-contain"
                  src={`/imagesWithTranslations/confirmation-example-${lang}.webp`}
                  alt={t("hero_Confirmación de asistencia por WhatsApp")!}
                  fill={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <YoutubeModal
        videoId="YlNo_jk-UzU"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      ></YoutubeModal>
    </>
  )
}
