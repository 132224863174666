export function TextFieldNew({
  label,
  children,
}: {
  label: string
  children: any
}) {
  return (
    <div className="">
      <div className="">
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>

        <div className="mt-1 flex rounded-md">{children}</div>
      </div>
    </div>
  )
}
