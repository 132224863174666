export { Hero } from "./Hero"
export { WhatsAppWidget, WhatsAppLink } from "./WhatsAppWidget"
export { SignInButton } from "./SignInButton"
export { AppShell } from "./AppShell"
export { WhatsAppTemplatePreview } from "./WhatsAppTemplatePreview"
export { Nav } from "./Nav"
export { HowDoesItWorkSection } from "./HowDoesItWorkSection"
export { DesignedFor } from "./DesignedFor"
export { AdvantagesOfConfirmafy } from "./AdvantagesOfConfirmafy"
export { default as Page } from "./Page"
export { Faq } from "./Faq"
export { SelectPlanBanner } from "./SelectPlanBanner"
export { Footer } from "./Footer"
export { CalendarList } from "./CalendarList"
export { YoutubeModal } from "./YoutubeModal"
export { SendMeReminderExample } from "./SendMeReminderExample"
export { AutoAgendaExample } from "./AutoAgendaExample"
export { PricingSlider } from "./PricingSlider"
export { GetPhoneNumberPopUp } from "./GetPhoneNumberPopUp"
export { CustomerLogos } from "./CustomerLogos"
export { BlogCTALink } from "./BlogCTALink"
