import { Menu, Transition } from "@headlessui/react"
import { useUserCountry } from "@hooks/useUserCountry"
import Link from "lib/I18N/Link"
import { classNames } from "lib/classNames"
import { mixpanel } from "lib/mixpanel"
import { useRouter } from "next/router"
import { Fragment } from "react"
import {
  ES,
  AR,
  BO,
  CL,
  CO,
  CR,
  CU,
  DO,
  EC,
  SV,
  GQ,
  GT,
  HN,
  MX,
  NI,
  PA,
  PY,
  PE,
  UY,
  VE,
  PT,
  BR,
  US,
  PR,
} from "country-flag-icons/react/3x2"

const languages = [
  { lang: "en", langName: "English" },
  { lang: "es", langName: "Español" },
  { lang: "pt", langName: "Português" },
]

// Country code to flag emoji for spanish speaking countries
const spanishSpeakingCountriesToFlag = {
  AR: AR,
  BO: BO,
  CL: CL,
  CO: CO,
  CR: CR,
  CU: CU,
  DO: DO,
  EC: EC,
  SV: SV,
  GQ: GQ,
  GT: GT,
  HN: HN,
  MX: MX,
  NI: NI,
  PA: PA,
  PY: PY,
  PE: PE,
  ES: ES,
  UY: UY,
  VE: VE,
  PR: PR,
}

export function LanguagePicker() {
  const router = useRouter()
  const { country, loading } = useUserCountry()

  let flagAndLanguage

  if (router.locale === "en") {
    flagAndLanguage = (
      <div className="flex items-center">
        EN<span className="border-l-1 mx-2 h-4 border"></span>
        <US className="w-4"></US>
      </div>
    )
  } else if (router.locale === "pt") {
    const Flag = country === "PT" ? PT : BR

    flagAndLanguage = (
      <div className="flex items-center">
        PT<span className="border-l-1 mx-2 h-4 border"></span>
        <Flag className="w-4"></Flag>
      </div>
    )
  } else {
    let Flag = spanishSpeakingCountriesToFlag["ES"]

    if (country) {
      // @ts-ignore
      Flag = spanishSpeakingCountriesToFlag[country as string] ?? Flag
    }

    flagAndLanguage = (
      <div className="flex items-center">
        ES<span className="border-l-1 mx-2 h-4 border"></span>
        <Flag className="w-4"></Flag>
      </div>
    )
  }

  return (
    <Menu as="div" className="relative">
      <Menu.Button type="button" className="text-gray-500 hover:text-gray-700">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64"
          />
        </svg> */}
        <div className="flex items-center text-xs">{flagAndLanguage}</div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {languages.map((lang) => (
              <Menu.Item key={lang.lang}>
                {({ active }) => (
                  <Link
                    href={router.pathname}
                    locale={lang.lang}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                    onClick={() => {
                      mixpanel.track("Language Changed", {
                        selectedLanguage: lang.lang,
                      })
                    }}
                  >
                    {lang.langName}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
