import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"

import dayjs, { Dayjs } from "./day"

import { daysInMonth, yyyymmdd } from "./date-fns"
import { weekdayNames } from "./weekday"

function classNames(...classes: unknown[]) {
  return classes.filter(Boolean).join(" ")
}

function SkeletonText(props: any) {
  return null
}

export type DatePickerProps = {
  /** which day of the week to render the calendar. Usually Sunday (=0) or Monday (=1) - default: Sunday */
  weekStart?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  /** Fires whenever a selected date is changed. */
  onChange: (date: Dayjs) => void
  /** Fires when the month is changed. */
  onMonthChange?: (date: Dayjs) => void
  /** which date is currently selected (not tracked from here) */
  selected?: Dayjs
  /** defaults to current date. */
  minDate?: Dayjs
  /** Furthest date selectable in the future, default = UNLIMITED */
  maxDate?: Dayjs
  /** locale, any IETF language tag, e.g. "hu-HU" - defaults to Browser settings */
  locale: string
  /** Defaults to [], which dates are not bookable. Array of valid dates like: ["2022-04-23", "2022-04-24"] */
  excludedDates?: string[]
  /** defaults to all, which dates are bookable (inverse of excludedDates) */
  includedDates?: string[]
  /** allows adding classes to the container */
  className?: string
  /** Shows a small loading spinner next to the month name */
  isLoading?: boolean
}

export const Day = ({
  date,
  active,
  ...props
}: JSX.IntrinsicElements["button"] & { active: boolean; date: Dayjs }) => {
  return (
    <>
      <button
        // className={`${active && " bg-indigo-600 text-white "} ${
        //   !inactive && "rounded-full bg-gray-200"
        // }  top-0 left-0 right-0 bottom-0 mx-auto w-full rounded-sm border border-transparent  text-center text-sm font-medium disabled:opacity-25`}
        className={classNames(
          " h-full w-full py-4",
          props.disabled && "cursor-default text-gray-400",
          !props.disabled && "font-semibold "
        )}
        {...props}
      >
        <span
          className={classNames(
            "mx-auto  flex h-9 w-9 items-center justify-center rounded-full",
            !props.disabled &&
              "bg-indigo-100  text-indigo-600 hover:bg-indigo-200 hover:shadow"
          )}
        >
          {date.date()}
        </span>
      </button>
      {/* {date.isToday() && (
        <span
          style={{ lineHeight: 0 }}
          className="absolute left-0 bottom-1 mx-auto h-2 w-full text-4xl text-indigo-600"
        >
          .
        </span>
      )} */}
    </>
  )
}

const Days = ({
  // minDate,
  excludedDates = [],
  includedDates,
  browsingDate,
  weekStart,
  selected,
  ...props
}: Omit<DatePickerProps, "locale" | "className" | "weekStart"> & {
  DayComponent?: React.FC<React.ComponentProps<typeof Day>>
  browsingDate: Dayjs
  weekStart: number
}) => {
  // Create placeholder elements for empty days in first week
  const weekdayOfFirst = browsingDate.day()

  const days: (Dayjs | null)[] = Array(
    (weekdayOfFirst - weekStart + 7) % 7
  ).fill(null)

  for (
    let day = 1, dayCount = daysInMonth(browsingDate);
    day <= dayCount;
    day++
  ) {
    const date = browsingDate.set("date", day)
    days.push(date)
  }

  return (
    <>
      {days.map((day, idx) => (
        <div
          key={day === null ? `e-${idx}` : `day-${day.format()}`}
          className="relative w-full "
        >
          {day === null ? (
            <div key={`e-${idx}`} />
          ) : (
            <Day
              date={day}
              onClick={() => {
                props.onChange(day)
              }}
              disabled={
                includedDates && !includedDates.includes(yyyymmdd(day))
                // // excludedDates.includes(yyyymmdd(day))
                // // Math.random() > 0.5
                // false
              }
              active={selected ? yyyymmdd(selected) === yyyymmdd(day) : false}
            />
          )}
        </div>
      ))}
    </>
  )
}

const DatePicker = ({
  weekStart = 1,
  className,
  locale,
  selected,
  onMonthChange,
  ...passThroughProps
}: DatePickerProps & Partial<React.ComponentProps<typeof Days>>) => {
  const browsingDate = passThroughProps.browsingDate || dayjs().startOf("month")

  const changeMonth = (newMonth: number) => {
    if (onMonthChange) {
      onMonthChange(browsingDate.add(newMonth, "month"))
    }
  }

  return (
    <div className={className}>
      <div className="mb-4 flex justify-between  text-xl font-light sm:px-0">
        <span className="w-1/2 ">
          <span className=" darker text-base font-semibold ">
            {browsingDate.locale(locale).format("MMMM")},
          </span>{" "}
          <span className=" light text-base font-medium">
            {browsingDate.format("YYYY")}
          </span>
        </span>
        <div className="text-black">
          <button
            onClick={() => changeMonth(-1)}
            className={
              "rounded-sm border p-2 opacity-60 hover:opacity-100 disabled:opacity-20"
            }
            disabled={!browsingDate.isAfter(dayjs())}
          >
            <ChevronLeftIcon className="h-5 w-5 " />
          </button>
          <button
            className="rounded-sm border border-l-0 p-2 opacity-60 hover:opacity-100"
            onClick={() => changeMonth(+1)}
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className=" mb-2 grid grid-cols-7 gap-4 border-t border-b text-center  md:mb-0 md:border-0">
        {weekdayNames(locale, weekStart, "short").map((weekDay) => (
          <div
            key={weekDay}
            className=" my-4 text-xs uppercase tracking-widest"
          >
            {weekDay}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-1 text-center">
        <Days
          weekStart={weekStart}
          selected={selected}
          {...passThroughProps}
          browsingDate={browsingDate}
        />
      </div>
    </div>
  )
}

export default DatePicker
