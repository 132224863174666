import Link from "lib/I18N/Link"
import { mixpanel } from "lib/mixpanel"

export function BlogCTALink({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) {
  return (
    <Link
      href={href}
      className="not-prose fixed bottom-4 left-1/2  flex w-full max-w-80 -translate-x-1/2 cursor-pointer items-center justify-center rounded-full bg-indigo-600 px-4 py-2 text-center text-white no-underline sm:max-w-96 md:max-w-110"
      onClick={() => {
        mixpanel.track("Blog CTA Link Clicked")
      }}
    >
      {children}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="currentColor"
        className="ml-1 inline-block h-4 w-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
        />
      </svg>
    </Link>
  )
}
