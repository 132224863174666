import useTranslateFunction from "@hooks/useTranslateFunction"

export function AdvantagesOfConfirmafy() {
  const { t } = useTranslateFunction()

  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="ventajas">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {t("advantagesOfConfirmafy_Ventajas de utilizar Confirmafy")}
        </h2>

        <div className="mx-auto mt-8 max-w-7xl divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 text-left sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          <div className="  group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
            <div>
              <span className="inline-flex rounded-lg bg-teal-50 p-3 text-teal-700 ring-4 ring-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                {t("advantagesOfConfirmafy_Más ingresos y menos ausentismo")}
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {t(
                  "advantagesOfConfirmafy_Por cada cliente que olvida su cita pierdes tiempo y dinero. Confirmafy se encarga de confirmar y recordar tus citas."
                )}
              </p>
            </div>
          </div>
          <div className="group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:relative">
            <div>
              <span className="inline-flex rounded-lg bg-purple-50 p-3 text-purple-700 ring-4 ring-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                {t(
                  "advantagesOfConfirmafy_Ahorra tiempo automizando mensajes de WhatsApp"
                )}
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {t(
                  "advantagesOfConfirmafy_¿Actualmente confirmas a tus clientes manualmente? ¿Los llamas o les escribes uno a uno? Deja que Confirmafy haga eso por ti."
                )}
              </p>
            </div>
          </div>
          <div className="group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
            <div>
              <span className="inline-flex rounded-lg bg-light-blue-50 p-3 text-light-blue-700 ring-4 ring-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                {t(
                  "advantagesOfConfirmafy_Los emails y SMS ya no son efectivos"
                )}
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {t(
                  "advantagesOfConfirmafy_Los emails y SMS no son efectivos, y las llamadas son intrusivas. Confirmafy usa WhatsApp para garantizar que tus clientes vean el mensaje y confirmen la cita."
                )}
              </p>
            </div>
          </div>
          <div className="group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
            <div>
              <span className="inline-flex rounded-lg bg-yellow-50 p-3 text-yellow-700 ring-4 ring-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                {t(
                  "advantagesOfConfirmafy_Productividad y orden para tu equipo"
                )}
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {t(
                  "advantagesOfConfirmafy_¿Tienes un asistente dedicado a confirmar citas? Libera su tiempo para que se encargue de tareas más importantes."
                )}
                <br />
                {t(
                  "advantagesOfConfirmafy_Crea cuentas para cada miembro de tu equipo y centraliza tu agenda."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
